import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import {
    Box,
    Grid,
    Typography,
    Stack
} from '@mui/material';
import styles from "../style/article.module.css";
import apiClient from "../http-common";

const ArticlePage = () => {
    const { id } = useParams();

    // Get data from API
    const [contentData, setContentData] = React.useState(null);
    const [newsData, setNewsData] = React.useState(null);

    const formatResponse = (res) => {
        return JSON.stringify(res, null, 2);
    };

    React.useEffect(() => {
        async function getContentData() {
            try {
                const res = await apiClient.get("get_single_post/" + id);

                const result = {
                    status: res.status + "-" + res.statusText,
                    headers: res.headers,
                    data: res.data,
                };
                console.log(result.data[0]);
                setContentData(result.data[0]);
            } catch (err) {
                setContentData(formatResponse(err.response?.data || err));
            }
        }

        async function getNewsData() {
            try {
                const res = await apiClient.get("get_artikel/post/?per_page=100");

                const result = {
                    status: res.status + "-" + res.statusText,
                    headers: res.headers,
                    data: res.data,
                };
                setNewsData(result.data);
            } catch (err) {
                setNewsData(formatResponse(err.response?.data || err));
            }
        }
        getContentData();
        getNewsData();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    }, [id]);

    const loadingWidget = <center>
        <Box
            mx={0}
            my={8}
            sx={{
                border: '16px solid #f3f3f3',
                borderRadius: '50%',
                borderTop: '16px solid #3498db',
                width: '120px',
                height: '120px',
                WebkitAnimation: 'spin 2s linear infinite', /* Safari */
                animation: 'spin 2s linear infinite',
            }}
        />
    </center>;

    return contentData != null
        ? <Box m={{ xs: 2, md: 4 }} p={0}>
            <Grid container direction="column" spacing={2} alignItems="stretch">
                <Grid item>
                    {/* Breadcrumbs */}
                    <Typography variant="subtitle1" sx={{ fontFamily: 'Poppins' }}>
                        <NavLink to='/news' style={{ textDecoration: 'none', color: 'black' }}>News</NavLink> {'>'} {contentData.post_title.substring(10)}
                    </Typography>
                </Grid>
                <Grid item>
                    {/* Content */}
                    <Box p={{ xs: 4, md: 7 }} backgroundColor="white" sx={{ boxSizing: 'border-box', borderRadius: '20px' }}>
                        <Grid container direction="column" justifyContent="flex-start" spacing={5}>
                            <Grid item>
                                {/* Title */}
                                <Stack spacing={1} alignItems="center">
                                    <item>
                                        {/* Title */}
                                        <Typography variant="h2" sx={{ fontFamily: 'Open Sans' }}>
                                            {contentData.post_title.substring(10)}
                                        </Typography>
                                    </item>
                                    <item>
                                        {/* Article Information */}
                                        <Typography variant="h4" sx={{ fontFamily: 'Open Sans', color: '#868686' }}>
                                            by Admin at {contentData.post_date.split(' ')[0]}
                                        </Typography>
                                    </item>
                                </Stack>
                            </Grid>
                            <Grid item>
                                {/* Content */}
                                <Typography
                                    sx={{ fontFamily: 'Poppins' }}
                                >
                                    <div className={styles.article} dangerouslySetInnerHTML={{ __html: contentData.post_content }}></div>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid >
                <Grid item>
                    {/* Latest Artice */}
                    <Box mt={2} p={{ xs: 2, md: 7 }} sx={{ boxSizing: 'border-box' }}>
                        <Grid container direction="column" spacing={5} alignItems="stretch">
                            <Grid item>
                                {/* Title */}
                                <Typography variant="h3" sx={{ fontFamily: 'Open Sans', fontWeight: 'bold', color: '#232323' }}>
                                    Latest Article
                                </Typography>
                            </Grid>
                            <Grid item>
                                {/* Article item */}
                                <Stack direction="column" spacing={1}>
                                    {
                                        // start
                                        newsData != null && Array.isArray(newsData)
                                            ? newsData.map((step, index) => {
                                                while (step.post_title.match(/Artikel -/))
                                                    return <item key={step.ID}>
                                                        <Box
                                                            py={2}
                                                            px={2}
                                                            sx={{ boxSizing: 'border-box', borderBottom: '1px solid #CEDBE6' }}
                                                        >
                                                            <a href={'/news/' + step.ID} style={{ textDecoration: 'none', color: '#084C84' }}>
                                                                <Typography variant="h4" sx={{ fontFamily: 'Poppins', '&:hover': { color: '#232323' } }}>
                                                                    {step.post_title}
                                                                </Typography>
                                                            </a>
                                                        </Box>
                                                    </item>
                                                return null;
                                            }) : loadingWidget

                                        // END
                                    }
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid >
        </Box > : loadingWidget
}

export default ArticlePage;