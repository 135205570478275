import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import {
    ChevronLeft as KeyboardArrowLeft,
    ChevronRight as KeyboardArrowRight,
} from 'react-feather';

function usePagination(data, itemsPerPage) {
    const [currentPage, setCurrentPage] = React.useState(1);
    const maxPage = Math.ceil(data.length / itemsPerPage);

    function currentData() {
        const begin = (currentPage - 1) * itemsPerPage;
        const end = begin + itemsPerPage;
        return data.slice(begin, end);
    }

    function next() {
        setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
    }

    function prev() {
        setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
    }

    function jump(page) {
        const pageNumber = Math.max(1, page);
        setCurrentPage(currentPage => Math.min(pageNumber, maxPage));
    }

    return { next, prev, jump, currentData, currentPage, maxPage };
}

function SwipeableTextMobileStepper(props) {
    // Responsive need
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.up("xs"));
    const md = useMediaQuery(theme.breakpoints.up("md"));
    const lg = useMediaQuery(theme.breakpoints.up("lg"));
    let perPage = 3;
    if (xs) {
        perPage = 1
    }
    if (md) {
        perPage = 2
    }
    if (lg) {
        perPage = 3
    }

    // Data Selection
    const data = props.data.map((step) => {
        while (step.post_title.match(/Testimoni -/))
            return {
                imgUrl: step.post_content.slice(step.post_content.indexOf("https"), step.post_content.indexOf("\" alt")),
                name: step.post_title.substring(12),
                position: step.post_content.slice(49, step.post_content.indexOf("</h2>")),
                text: step.post_content.slice((3 + step.post_content.indexOf("<p>")), step.post_content.indexOf("</p>")),
            }
        return null;
    });
    const selectedData = [];
    for (let i = 0; i < data.length; i++) {
        if (data[i] !== null) selectedData.push(data[i]);
    }

    // Pagination needs
    const PER_PAGE = perPage;
    const _DATA = usePagination(selectedData, PER_PAGE);

    const testimonyBox = (imgUrl, name, position, text) => {
        return <Grid item>
            <Box
                sx={{
                    border: '1px solid #084C84',
                    borderRadius: '10px',
                    boxSizing: 'border-box',
                    overflow: 'hidden',
                    width: {
                        xs: 270,
                        md: 320
                    },
                    height: 350,
                }}
            >
                <Grid container direction="column" sx={{ height: '100%' }} justifyContent="space-between" alignItems="stretch">
                    <Grid item xs={7} md={8}>
                        {/* Profile */}
                        <Stack direction="column" p={2} justifyContent="center" alignItems="center" sx={{ maxHeight: 230, overflow: 'hidden' }}>
                            <item>
                                {/* Foto Profile */}
                                <Box
                                    sx={{
                                        height: {
                                            xs: 100,
                                            md: 130
                                        },
                                        width: {
                                            xs: 100,
                                            md: 130
                                        },
                                        borderRadius: '50%',
                                        overflow: 'hidden',
                                        backgroundImage: 'url(' + imgUrl + ')',
                                        backgroundSize: 'cover',
                                        mb: 1,
                                    }}
                                />
                            </item>
                            <item>
                                <Typography variant="h5" sx={{
                                    color: '#084C84', textAlign: 'center', fontWeight: 'bold',
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 1,
                                }}>
                                    {name}
                                </Typography>
                            </item>
                            <item>
                                <Typography variant="subtitle1" sx={{
                                    textAlign: 'center',
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 1,
                                }}>
                                    {position}
                                </Typography>
                            </item>
                        </Stack>
                    </Grid>
                    <Grid item xs={5} md={4}>
                        {/* Teks testimoni */}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#084C84',
                                color: 'white',
                                boxSizing: 'border-box',
                                width: '100%',
                                height: '100%',
                                p: 2
                            }}
                        >
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontFamily: 'Poppins',
                                    color: 'white',
                                    textAlign: 'center',
                                    wordWrap: 'break-word',
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 3,
                                }}>
                                {text}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid >
            </Box >
        </Grid >
    };

    return (
        <Box sx={{ width: '100%', overflow: 'hidden' }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item display={{ xs: 'none', md: 'block' }}>
                    <Button
                        onClick={_DATA.prev}
                        disabled={_DATA.currentPage === 1}
                        display={{ xs: 'none', md: 'block' }}>
                        <KeyboardArrowLeft />
                    </Button>
                </Grid>
                <Grid item xs>
                    <Stack mb={2} direction="row" spacing={{ xs: 0, md: 2, lg: 0 }} justifyContent={{ xs: "center", lg: "space-evenly" }}>
                        {_DATA.currentData().map(v => {
                            return (
                                testimonyBox(v.imgUrl, v.name, v.position, v.text)
                            )
                        })}
                    </Stack>
                </Grid>
                <Grid item display={{ xs: 'none', md: 'block' }}>
                    <Button
                        onClick={_DATA.next} disabled={_DATA.currentPage === _DATA.maxPage}
                    >
                        <KeyboardArrowRight />
                    </Button></Grid>
            </Grid>
            {/* Nav Button */}
            <Stack direction="row" justifyContent="center" display={{ xs: 'flex', md: 'none' }}>
                <Button size="small" onClick={_DATA.prev} disabled={_DATA.currentPage === 1}>
                    <KeyboardArrowLeft />
                </Button>
                <Button
                    size="small"
                    onClick={_DATA.next} disabled={_DATA.currentPage === _DATA.maxPage}
                >
                    <KeyboardArrowRight />
                </Button>
            </Stack>
        </Box>
    );
}

export default SwipeableTextMobileStepper;