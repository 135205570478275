import React, { useEffect, useState } from 'react';
import apiClient from "../http-common";
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Grid,
    IconButton,
    Typography,
    Stack,
    Grow,
    Dialog,
} from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { ArrowRightCircle, Briefcase, CheckCircle, File, MessageSquare, Users, } from 'react-feather';
import { NavLink } from 'react-router-dom';

// import assets
import Logo from '../assets/img/logompj.png';
import Tri from '../assets/img/partners/3.png';
import Ceragon from '../assets/img/partners/ceragon.png';
import Fiberhome from '../assets/img/partners/fiberhome.jpg';
import Huawei from '../assets/img/partners/huawei.png';
import Indosat from '../assets/img/partners/indosat.png';
import Mcn from '../assets/img/partners/mcn.png';
import Miratel from '../assets/img/partners/miratel.png';
import Nsn from '../assets/img/partners/nsn.png';
import Protelindo from '../assets/img/partners/protelindo.jpg';
import Rbg from '../assets/img/partners/rbg.jpg';
import Telkomsel from '../assets/img/partners/telkomsel.png';
import Telkomsigma from '../assets/img/partners/telkomsigma.jpg';
import Xl from '../assets/img/partners/xl.png';
import Zte from '../assets/img/partners/zte.png';
import Kegiatan1 from '../assets/img/kegiatan1.jpeg';
import LoadingWidget from '../widget/loadingWidget';
import SwipeableTextMobileStepper from '../widget/homeTestimony';

const GrowTransition = React.forwardRef(function GrowTransition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

const dummyImage = [
    Kegiatan1,
    Kegiatan1,
    Kegiatan1,
    Kegiatan1,
    Kegiatan1,
]

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const partnerImgs = [
    Tri,
    Ceragon,
    Fiberhome,
    Huawei,
    Indosat,
    Mcn,
    Miratel,
    Nsn,
    Protelindo,
    Rbg,
    Telkomsel,
    Telkomsigma,
    Xl,
    Zte,
];

const Home = () => {
    const [getResult, setGetResult] = useState(null);
    const [carouselData, setCarouselData] = useState(null);
    const formatResponse = (res) => {
        return JSON.stringify(res, null, 2);
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
        async function getAllData() {
            try {
                const res = await apiClient.get("get_artikel/post/?per_page=100");

                const result = {
                    status: res.status + "-" + res.statusText,
                    headers: res.headers,
                    data: res.data,
                };
                setGetResult(result.data);
                return result.data;
            } catch (err) {
                setGetResult(formatResponse(err.response?.data || err));
            }
        }

        (async () => {
            const thedata = await getAllData();
            const dataSelected = [];
            if (thedata !== null && Array.isArray(thedata)) {
                for (let i = 0; i < thedata.length; i++) {
                    if (thedata[i].post_title.match(/Carousel -/)) {
                        dataSelected.push(thedata[i]);
                    }
                }
            }
            console.log(dataSelected);
            setCarouselData(dataSelected);
        })();
    }, []);

    const theme = useTheme();
    const [firstActiveStep, setFirstActiveStep] = React.useState(0);

    const handleFirstStepChange = (step) => {
        setFirstActiveStep(step);
    };

    const [openImage, setOpenImage] = React.useState(false);
    const handleOpenImage = (img) => {
        setOpenImage(true);
        handleCurrentImage(img);
    }
    const handleCloseImage = () => {
        setOpenImage(false);
    }

    const [currentImage, setCurrentImage] = React.useState(null);
    const handleCurrentImage = (img) => {
        setCurrentImage(img);
    }

    return (
        getResult != null && Array.isArray(getResult)
            ? <Box
                id="Home"
                m={0}
                p={0}
            >
                <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={12}>
                    <Grid item>
                        {/* CAROUSEL */}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative',
                                my: { xs: 2, md: 4 },
                                mx: { xs: 'auto', sm: 4 },
                                borderRadius: '10px',
                                overflow: 'hidden',
                                maxHeight: {
                                    xs: 200,
                                    sm: 300,
                                    md: 456,
                                },
                                maxWidth: {
                                    xs: 350,
                                    sm: '100%',
                                },
                                backgroundColor: '#F3F3F3'
                            }}>
                            <AutoPlaySwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={firstActiveStep}
                                onChangeIndex={handleFirstStepChange}
                                enableMouseEvents
                            >
                                {carouselData.map((step, index) => {
                                    return <div key={index}>
                                        {Math.abs(firstActiveStep - index) <= 2 ? (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    position: 'relative',
                                                    maxHeight: '456px',
                                                    overflow: 'hidden',
                                                }}
                                            >
                                                <img
                                                    src={step.post_content.slice(step.post_content.indexOf("https"), step.post_content.indexOf("\" alt"))}
                                                    alt={step.post_title.substring(11)}
                                                    width="100%"
                                                    height="100%"
                                                />
                                                <Box
                                                    width="100%"
                                                    height="100%"
                                                    display={{ xs: 'none', md: 'none', lg: 'flex' }}
                                                    sx={{
                                                        position: 'absolute',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-end',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            width: '30%',
                                                            height: '80%',
                                                            py: 3,
                                                            px: 6,
                                                            mr: 4,
                                                            backgroundColor: 'rgba(255,255,255,0.7)',
                                                            borderRadius: '20px',
                                                            boxSizing: 'border-box',
                                                            overflow: 'hidden',
                                                        }}
                                                    >
                                                        <Grid container direction="column" justifyContent="flex-start" alignItems="center" spacing={1}>
                                                            <Grid item>
                                                                <Typography variant="h3" sx={{ fontFamily: 'Open Sans', color: '#084C84' }}>
                                                                    {step.post_title.substring(11)}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant="subtitle1" sx={{ fontFamily: 'Poppins', }}>
                                                                    {step.post_content.slice((3 + step.post_content.indexOf("<p>")), step.post_content.indexOf("</p>"))}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        ) : null}
                                    </div>
                                })}
                            </AutoPlaySwipeableViews>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    bottom: 8,
                                }}
                            >
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={0.5}
                                >
                                    {carouselData.map((step, index) => (
                                        <div key={'carousel' + index}>
                                            <Grid item>
                                                <IconButton
                                                    aria-label="choose"
                                                    color="primary"
                                                    onClick={() => handleFirstStepChange(index)}
                                                >
                                                    <Box
                                                        sx={{
                                                            width: '16px',
                                                            height: '16px',
                                                            borderRadius: '50%',
                                                            backgroundColor: index === firstActiveStep ? '#084C84' : '#D9D9D9',
                                                            [theme.breakpoints.down('sm')]: {
                                                                width: '12px',
                                                                height: '12px',
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </Grid>
                                        </div>
                                    ))}
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item>
                        <Grid container direction="column" justifyContent="flex-start" alignItems={{ xs: 'flex-start', sm: 'center' }} spacing={3}>
                            <Grid item>
                                <Stack direction="row" alignItems="center" spacing={{ xs: 2, md: 4 }}>
                                    <item>
                                        <Box
                                            width={{ xs: '50px', md: '75px', lg: '100px' }}
                                            height={{ xs: '50px', md: '75px', lg: '100px' }}
                                            sx={{ position: 'relative' }}
                                            display={{ xs: 'none', md: 'block' }}
                                        >
                                            <img src={Logo} alt="Logo MPJ" width="100%" height="100%" />
                                        </Box>
                                    </item>
                                    <item>
                                        <Typography variant="h3" sx={{ fontFamily: 'Open Sans', fontWeight: 'bold' }}>
                                            PT. Multi Piranti Jaya
                                        </Typography>
                                    </item>
                                    <item>
                                        <Box
                                            width={{ xs: '50px', md: '75px', lg: '100px' }}
                                            height={{ xs: '50px', md: '75px', lg: '100px' }}
                                            sx={{ position: 'relative' }}
                                            display={{ xs: 'block', md: 'none' }}
                                        >
                                            <img src={Logo} alt="Logo MPJ" width="100%" height="100%" />
                                        </Box>
                                    </item>
                                </Stack>
                            </Grid>
                            {
                                getResult.map((step, index) => {
                                    while (step.post_title.match(/Home -/))
                                        return <Grid item>
                                            <Box
                                                width="100%"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <Box maxWidth={{ xs: '90%', md: '70%' }}>
                                                    <Typography variant="body1" sx={{ fontFamily: 'Poppins', textAlign: { xs: 'left', md: 'center' }, color: '#212121' }}>
                                                        <div dangerouslySetInnerHTML={{ __html: step.post_content }} />
                                                    </Typography>

                                                </Box>
                                            </Box>
                                        </Grid>
                                    return null;
                                })
                            }
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Box
                            width="100%"
                            sx={{
                                position: 'relative',
                                backgroundImage: "url('https://myrobin.id/wp-content/uploads/2022/10/Customer.png')",
                                backgroundSize: 'cover',
                            }}
                        >
                            <Box
                                py={12}
                                backgroundColor="rgba(0,0,0,0.6)"
                                width="100%"
                            >
                                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={10}>
                                    <Grid item>
                                        <Box
                                            backgroundColor="rgba(255,255,255,0.8)"
                                            px={1}
                                        >
                                            <Typography variant="h2" sx={{ fontFamily: 'Open Sans', color: '#084C84' }}>
                                                OUR COMMITMENT<br />
                                                TO CUSTOMER
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <Stack direction="column" spacing={6}>
                                            <item>
                                                <Stack direction="row" alignItems="center" spacing={4}>
                                                    <item>
                                                        <Box
                                                            p={1}
                                                            backgroundColor="rgba(255,255,255,0.6)"
                                                            sx={{
                                                                borderRadius: '25px',
                                                                width: {
                                                                    xs: '50px',
                                                                    md: '100px'
                                                                },
                                                                height: {
                                                                    xs: '50px',
                                                                    md: '100px'
                                                                },
                                                            }}
                                                        >
                                                            <CheckCircle
                                                                color="#084C84"
                                                                width={{
                                                                    xs: '50px',
                                                                    md: '100px'
                                                                }}
                                                                height={{
                                                                    xs: '50px',
                                                                    md: '100px'
                                                                }}
                                                            />
                                                        </Box>
                                                    </item>
                                                    <item>
                                                        <Typography variant="h4" sx={{ color: 'white', fontFamily: 'Poppins', fontWeight: 'bold' }}>
                                                            Quality Guaranty
                                                        </Typography>
                                                    </item>
                                                </Stack>
                                            </item>
                                            <item>
                                                <Stack direction="row" alignItems="center" spacing={4}>
                                                    <item>
                                                        <Box p={1} backgroundColor="rgba(255,255,255,0.6)" sx={{
                                                            borderRadius: '25px',
                                                            width: {
                                                                xs: '50px',
                                                                md: '100px'
                                                            },
                                                            height: {
                                                                xs: '50px',
                                                                md: '100px'
                                                            },
                                                        }}>
                                                            <Users
                                                                color="#084C84"
                                                                width={{
                                                                    xs: '50px',
                                                                    md: '100px'
                                                                }}
                                                                height={{
                                                                    xs: '50px',
                                                                    md: '100px'
                                                                }}
                                                            />
                                                        </Box>
                                                    </item>
                                                    <item>
                                                        <Typography variant="h4" sx={{ color: 'white', fontFamily: 'Poppins', fontWeight: 'bold' }}>
                                                            Customer Oriented
                                                        </Typography>
                                                    </item>
                                                </Stack>
                                            </item>
                                            <item>
                                                <Stack direction="row" alignItems="center" spacing={4}>
                                                    <item>
                                                        <Box p={1} backgroundColor="rgba(255,255,255,0.6)" sx={{
                                                            borderRadius: '25px',
                                                            width: {
                                                                xs: '50px',
                                                                md: '100px'
                                                            },
                                                            height: {
                                                                xs: '50px',
                                                                md: '100px'
                                                            },
                                                        }}>
                                                            <Briefcase
                                                                color="#084C84"
                                                                width={{
                                                                    xs: '50px',
                                                                    md: '100px'
                                                                }}
                                                                height={{
                                                                    xs: '50px',
                                                                    md: '100px'
                                                                }}
                                                            />
                                                        </Box>
                                                    </item>
                                                    <item>
                                                        <Typography variant="h4" sx={{ color: 'white', fontFamily: 'Poppins', fontWeight: 'bold' }}>
                                                            Professional Worker
                                                        </Typography>
                                                    </item>
                                                </Stack>
                                            </item>
                                        </Stack>
                                    </Grid>
                                </Grid>

                            </Box>
                        </Box>
                    </Grid>

                    <Grid item>
                        {/* Partners */}
                        <Grid container direction="column" spacing={6} px={{ xs: 2, md: 11 }} sx={{ boxSizing: 'border-box' }}>
                            <Grid item>
                                <Stack direction="row" justifyContent={{ xs: 'center', md: 'flex-end' }} alignItems="center" spacing={3}>
                                    <item>
                                        <Typography variant="h2" sx={{ fontFamily: 'Open Sans', fontWeight: 'bold', color: '#084C84' }}>
                                            Partners
                                        </Typography>
                                    </item>
                                    <item>
                                        <Box
                                            width={{ xs: 50, md: 100 }}
                                            height={{ xs: 50, md: 100 }}
                                        >
                                            <File color="#084C84"
                                                width={{ xs: 50, md: 100 }}
                                                height={{ xs: 50, md: 100 }}
                                            />
                                        </Box>
                                    </item>
                                </Stack>
                            </Grid>
                            <Grid item>
                                <Grid container justifyContent="center" alignItems="center" rowSpacing={12} columnSpacing={3}>
                                    {
                                        partnerImgs.map((step, index) => {
                                            return <Grid item>
                                                <Box
                                                    sx={{
                                                        height: {
                                                            xs: '50px',
                                                            md: '100px'
                                                        },
                                                        width: 'auto',
                                                        maxWidth: '100%',
                                                        overflow: 'hidden'
                                                    }}
                                                >
                                                    <img src={step} alt="" height="75%" />
                                                </Box>
                                            </Grid>
                                        })
                                    }
                                    <Grid item>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Box
                            width="100%"
                            backgroundColor="#232323"
                            py={3}
                            sx={{ boxSizing: 'border-box' }}
                        >
                            <Grid container direction="column" justifyContent="center" alignItems="center" spacing={11}>
                                <Grid item>
                                    <Typography variant="h2" sx={{ color: 'white', fontFamily: 'Open Sans', fontWeight: 'bold' }}>
                                        Gallery
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {/* Image */}
                                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
                                        {dummyImage.map((step, index) => {
                                            return <Grid item>
                                                <Box
                                                    onClick={() => { handleOpenImage(step); }}
                                                    sx={{
                                                        height: { xs: '100px', md: '200px' },
                                                        width: { xs: '100px', md: '200px' },
                                                        backgroundImage: "url(" + step + ")",
                                                        backgroundSize: 'cover',
                                                        borderRadius: '20px',
                                                        overflow: 'hidden',
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </Grid>
                                        })}
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <NavLink style={{ textDecoration: 'none' }} to="/gallery">
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: '#084C84',
                                                "&:hover": {
                                                    color: 'white',
                                                }
                                            }}
                                        >

                                            <Typography variant="subtitle1" sx={{
                                                fontFamily: 'Poppins', mr: 2,
                                            }}>
                                                See Our Gallery
                                            </Typography>
                                            <ArrowRightCircle size={24} />
                                        </Box>
                                    </NavLink>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid item>
                        {/* Testimoni */}
                        <Stack spacing={3}>
                            <item>
                                <Stack direction="row" justifyContent={{ xs: 'center', md: 'flex-start' }} alignItems="center" spacing={3} ml={{ xs: 0, md: 11 }}>
                                    <item>
                                        <Box
                                            height={{
                                                xs: 50,
                                                md: 100,
                                            }}
                                            width={{
                                                xs: 50,
                                                md: 100,
                                            }}
                                        >
                                            <MessageSquare
                                                color="#084C84"
                                                height={{
                                                    xs: 50,
                                                    md: 100,
                                                }}
                                                width={{
                                                    xs: 50,
                                                    md: 100,
                                                }}
                                            />
                                        </Box>
                                    </item>
                                    <item>
                                        <Typography variant="h2" sx={{ fontFamily: 'Open Sans', fontWeight: 'bold', color: '#084C84' }}>
                                            Testimony
                                        </Typography>
                                    </item>
                                </Stack>
                            </item>

                            <SwipeableTextMobileStepper data={getResult ?? []} perPage={3} />
                        </Stack>

                    </Grid>

                    <Grid item>
                        {/* Back to top */}
                    </Grid>

                </Grid>


                {/* Open Image */}
                <Dialog
                    fullScreen
                    open={openImage}
                    onClose={handleCloseImage}
                    TransitionComponent={GrowTransition}
                    PaperProps={{ style: { backgroundColor: 'transparent' }, onClick: handleCloseImage }}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                        height="100%"
                    >
                        <img src={currentImage} alt="" maxHeight="100%" />
                    </Box>
                </Dialog>
            </Box >
            : <LoadingWidget />
    )
}

export default Home;