import React from 'react';
import {
    Box,
    Grid,
    Typography,
    TextField,
    Stack,
    Button
} from '@mui/material';
import HeaderPage from '../component/headerPage';

// Import Assets
import Background from '../assets/img/activityImg.jpeg';

const Contact = () => {

    React.useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    }, []);

    return <Box m={0} p={0}>
        <Stack direction="column">
            <item>
                <HeaderPage title="Contact Us" img={Background} />
            </item>
            <item>
                <Grid container direction={{ xs: 'column', md: 'row' }} justifyContent="flex-start" alignItems={{ xs: 'stretch', md: "flex-start" }}>
                    {/* <Grid item xs={12} md={6}>
                        <Box
                            // height={width > height ? width / 2 : height / 2}
                            height='680px'
                            sx={{ p: 5, boxSizing: 'border-box' }}
                        >
                            <Box
                                width="100%"
                            >

                                <Grid container direction="column" alignItems="stretch" justifyContent="center" spacing={3}>
                                    <Grid item>
                                        <Typography variant="h4" sx={{ textAlign: 'center', fontFamily: 'Open Sans', fontWeight: 'bold' }}>
                                            Send a Message
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <TextField id="outlined-basic" label="Name" variant="outlined" fullWidth />
                                    </Grid>
                                    <Grid item>
                                        <TextField id="outlined-basic" label="Email" variant="outlined" fullWidth />
                                    </Grid>
                                    <Grid item>
                                        <TextField id="outlined-basic" label="Phone Number" variant="outlined" fullWidth />
                                    </Grid>
                                    <Grid item>
                                        <TextField id="outlined-basic" label="Message" variant="outlined" multiline rows={8} fullWidth />
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" fullWidth>Send</Button>
                                    </Grid>
                                </Grid>

                            </Box>
                        </Box>
                    </Grid> */}
                    <Grid item xs={12} md={6}>
                        <Box
                            height="680px"
                            width="100%"
                            backgroundColor="grey"
                            sx={{ overflow: 'hidden' }}
                        >
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.915961217442!2d106.92114711431068!3d-6.274780063170058!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e698d37fa94809b%3A0x642b093bf16ef0a2!2sPT.%20Multi%20Piranti%20Jaya!5e0!3m2!1sen!2sid!4v1676176262848!5m2!1sen!2sid"
                                title="Multi Piranti Jaya Location"
                                width="100%"
                                height="100%"
                                allowfullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                                style={{ border: 0 }}
                            />
                        </Box>

                    </Grid>
                </Grid>
            </item>
        </Stack>
    </Box>
}

export default Contact;
