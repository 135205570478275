import { createTheme } from "@mui/material";

const theme = createTheme();

theme.typography.h2 = {
    fontSize: '2rem',
    [theme.breakpoints.up('sm')]: {
        fontSize: '2.375rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '3.75rem',
    },
}

theme.typography.h3 = {
    fontSize: '1.4rem',
    [theme.breakpoints.up('sm')]: {
        fontSize: '1.8rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '2.4rem',
    },
    fontWeight: 'normal',
};

theme.typography.h4 = {
    fontSize: '1.2rem',
    [theme.breakpoints.up('sm')]: {
        fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '2.125rem',
    },
    fontWeight: 'normal',
};

theme.typography.h6 = {
    fontSize: '1rem',
    [theme.breakpoints.up('sm')]: {
        fontSize: '1.25rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1.5rem',
    },
}

export default theme;