import React, { useEffect, useState } from 'react';
import apiClient from "../http-common";
import {
    Box,
    Typography,
    Stack,
    Grid,
    Dialog,
    DialogContent,
    Paper,
    AppBar,
    Toolbar,
    IconButton,
    Slide,
    Grow
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    X as XIcon
} from 'react-feather';

// Import Assets
import Background from '../assets/img/careerImg.png';
import HeaderPage from '../component/headerPage';
import LoadingWidget from '../widget/loadingWidget';

const SlideTransition = React.forwardRef(function SlideTransition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const GrowTransition = React.forwardRef(function GrowTransition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

const Career = () => {
    const [getResult, setGetResult] = useState(null);
    const formatResponse = (res) => {
        return JSON.stringify(res, null, 2);
    };

    useEffect(() => {
        async function getAllData() {
            try {
                const res = await apiClient.get("get_artikel/post/?per_page=100");

                const result = {
                    status: res.status + "-" + res.statusText,
                    headers: res.headers,
                    data: res.data,
                };
                setGetResult(result.data);
            } catch (err) {
                setGetResult(formatResponse(err.response?.data || err));
            }
        }
        getAllData();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    }, []);

    // Get viewport size
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [contentOpen, setContentOpen] = React.useState(false);
    const [currentContent, setCurrentContent] = React.useState(null);

    const handleContentOpen = () => {
        setContentOpen(true);
    }

    const handleContentClose = () => {
        setContentOpen(false);
    }

    const openDialog = (imgUrl, title, description) => {
        const dataContent = {
            imgUrl: imgUrl,
            title: title,
            description: description,
        };
        setCurrentContent(dataContent);
        handleContentOpen();
    }

    const [openImage, setOpenImage] = React.useState(false);
    const handleOpenImage = (img) => {
        setOpenImage(true);
        handleCurrentImage(img);
    }
    const handleCloseImage = () => {
        setOpenImage(false);
    }

    const [currentImage, setCurrentImage] = React.useState(null);
    const handleCurrentImage = (img) => {
        setCurrentImage(img);
    }

    return <Box m={0} mb={4} p={0}>
        <Stack direction="column" spacing={4}>
            <item>
                <HeaderPage title="Careers" img={Background} />
            </item>
            <item>
                <Grid container justifyContent="center" alignItems='flex-start' rowSpacing={7} columnSpacing={7}>
                    {
                        getResult != null && Array.isArray(getResult)
                            ?
                            getResult.map((step, index) => {
                                while (step.post_title.match(/Career -/))
                                    return <Grid item>
                                        <Box
                                            sx={{ borderRadius: '20px', overflow: 'hidden' }}
                                        >
                                            <Paper
                                                onClick={() => openDialog(step.post_content.slice(step.post_content.indexOf("https"), step.post_content.indexOf("\" alt")), step.post_title.substring(9), step.post_content.slice(step.post_content.indexOf("<p>") + 3, step.post_content.indexOf("</p>")))}
                                                sx={{ cursor: 'pointer' }}
                                            >
                                                <Stack direction="column" >
                                                    <item>
                                                        <Box
                                                            sx={{
                                                                width: '300px',
                                                                height: '250px',
                                                                backgroundImage: 'url(' + step.post_content.slice(step.post_content.indexOf("https"), step.post_content.indexOf("\" alt")) + ')',
                                                                backgroundSize: 'cover',
                                                                backgroundPosition: '50% 50%',
                                                            }}
                                                        />
                                                    </item>
                                                    <item>
                                                        <Box
                                                            sx={{
                                                                width: '100%',
                                                                py: 1,
                                                                backgroundColor: '#084C84',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                            }}
                                                        >
                                                            <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                                                                {step.post_title.substring(9)}
                                                            </Typography>
                                                        </Box>
                                                    </item>
                                                </Stack>
                                            </Paper>
                                        </Box>
                                    </Grid>
                                return null;
                            })
                            : <LoadingWidget />
                    }
                </Grid>
            </item>
        </Stack>
        {
            currentContent != null
                ? <Dialog
                    fullScreen={fullScreen}
                    open={contentOpen}
                    onClose={handleContentClose}
                    TransitionComponent={SlideTransition}
                    maxWidth="lg"
                    PaperProps={{ style: { borderRadius: '20px' } }}
                >
                    <AppBar sx={{ position: 'relative', backgroundColor: '#084C84' }}>
                        <Toolbar>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                {currentContent.title}
                            </Typography>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleContentClose}
                                aria-label="close"
                            >
                                <XIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <Grid container columnSpacing={2} rowSpacing={4} alignItems="flex-start" justifyContent="center" width="100%">
                            <Grid item xs={12} md={4} overflow="hidden">
                                <Box
                                    onClick={() => handleOpenImage(currentContent.imgUrl)}
                                    width="360px"
                                    height="360px"
                                    overflow="hidden"
                                    sx={{
                                        backgroundImage: 'url(' + currentContent.imgUrl + ')',
                                        backgroundSize: 'cover',
                                        backgroundPosition: '50% 50%'
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={8} width="100%">
                                <Box
                                    minWidth={{ xs: 300, md: 500, lg: '700' }}
                                >
                                    <Typography variant="h5" sx={{ color: 'black' }}>
                                        Description
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        <div dangerouslySetInnerHTML={{ __html: currentContent.description }}></div>
                                        {/* {currentContent.description} */}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
                : null
        }
        {/* Career Details Pop Up */}

        {/* Open Image */}
        <Dialog
            fullScreen
            open={openImage}
            onClose={handleCloseImage}
            TransitionComponent={GrowTransition}
            PaperProps={{ style: { backgroundColor: 'transparent' }, onClick: handleCloseImage }}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
            >
                <img src={currentImage} alt="" maxHeight="100%" />
            </Box>
        </Dialog>
    </Box >
}

export default Career;
