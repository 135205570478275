import React from 'react';
import {
    Box,
    Typography
} from '@mui/material';

const HeaderPage = (props) => {
    return <Box
        sx={{
            width: '100%',
            backgroundImage: 'url(' + props.img + ')',
            backgroundSize: 'cover',
            backgroundPosition: '50% 50%'
        }}
    >
        <Box
            sx={{
                width: '100%',
                py: 12,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(49,52,54,0.6)',
            }}
        >
            <Typography variant="h3" sx={{ color: 'white', fontFamily: 'Poppins', fontWeight: 'bold', textTransform: 'uppercase' }}>
                {props.title}
            </Typography>
        </Box>
    </Box>
}

export default HeaderPage;