import React, { useEffect, useState } from 'react';
import apiClient from "../http-common";
import {
    Stack,
    Box,
    Typography
} from '@mui/material';
import HeaderPage from '../component/headerPage';

// Import Assets
import Background from '../assets/img/orgImg.png';
import LoadingWidget from '../widget/loadingWidget';

const TitleWidget = (props) => {
    return <Box
        sx={{
            width: '100%',
            py: 1,
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#084C84',
            borderRadius: '10px'
        }}
    >
        <Typography variant="h4" sx={{ fontFamily: 'Open Sans', color: 'white', fontWeight: 'bold' }}>
            {props.title}
        </Typography>
    </Box>
}

const Organization = () => {
    const [getResult, setGetResult] = useState(null);
    const formatResponse = (res) => {
        return JSON.stringify(res, null, 2);
    };

    useEffect(() => {
        async function getAllData() {
            try {
                const res = await apiClient.get("get_artikel/post/?per_page=100");

                const result = {
                    status: res.status + "-" + res.statusText,
                    headers: res.headers,
                    data: res.data,
                };
                setGetResult(result.data);
            } catch (err) {
                setGetResult(formatResponse(err.response?.data || err));
            }
        }
        getAllData();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    }, []);

    return <Box
        id="About"
        m={0}
        p={0}
    >
        <Stack direction="column" spacing={4}>
            <item>
                <HeaderPage title="Organization" img={Background} />
            </item>
            <item>
                <Box
                    mx={{ xs: 2, md: 4 }}
                    mb={3}
                    sx={{
                        boxSizing: 'border-box',
                        borderRadius: '20px'
                    }}
                >
                    <Stack direction="column" spacing={{ xs: 2, md: 4 }}>
                        <item>
                            <Box
                                sx={{
                                    width: '100%',
                                    p: { xs: 4, md: 8 },
                                    boxSizing: 'border-box',
                                    backgroundColor: 'white',
                                }}
                            >

                                <Stack direction="column" spacing={7}>

                                    {/* Title */}
                                    <item>
                                        <TitleWidget title="Commissioner" />
                                    </item>
                                    <item>
                                        <Stack direction="column" spacing={7}>
                                            {
                                                getResult != null && Array.isArray(getResult)
                                                    ?
                                                    getResult.map((step, index) => {
                                                        while (step.post_title.match(/Organization - Commissioner/))
                                                            return <item>
                                                                <Stack direction={{ xs: 'column', md: 'row' }} alignItems={{ xs: 'center', md: 'flex-start' }} spacing={6}>
                                                                    {/* Profile Image */}
                                                                    <item>
                                                                        <Box
                                                                            sx={{
                                                                                width: '200px',
                                                                                height: '200px',
                                                                                borderRadius: '50%',
                                                                                backgroundImage: 'url(' + step.post_content.slice(step.post_content.indexOf("https"), step.post_content.indexOf("\" alt")) + ')',
                                                                                backgroundSize: 'cover',
                                                                            }}
                                                                        />
                                                                    </item>

                                                                    {/* Profile Text */}
                                                                    <item>
                                                                        <Stack direction="column" spacing={2}>
                                                                            <item>
                                                                                <Typography variant="h5" sx={{ color: '#232323', fontWeight: 'bold', fontFamily: 'Open Sans', }}>
                                                                                    {step.post_content.slice((29 + step.post_content.indexOf("<h3")), step.post_content.indexOf("</h3>"))}
                                                                                </Typography>
                                                                            </item>
                                                                            <item>
                                                                                <Typography variant="h5" sx={{ color: '#084C84', fontWeight: 'bold', fontFamily: 'Open Sans', }}>
                                                                                    {step.post_content.slice(49, step.post_content.indexOf("</h2>"))}
                                                                                </Typography>
                                                                            </item>
                                                                            <item>
                                                                                <Typography variant="h6" sx={{ color: '#232323', fontFamily: 'Poppins', fontWeight: 'normal' }}>
                                                                                    {step.post_content.slice((3 + step.post_content.indexOf("<p>")), step.post_content.indexOf("</p>"))}
                                                                                </Typography>
                                                                            </item>
                                                                        </Stack>
                                                                    </item>

                                                                </Stack>
                                                            </item>
                                                        return null;
                                                    })
                                                    : <LoadingWidget />
                                            }
                                        </Stack>
                                    </item>
                                </Stack>
                            </Box>
                        </item>
                        <item>
                            <Box
                                sx={{
                                    width: '100%',
                                    p: { xs: 4, md: 8 },
                                    boxSizing: 'border-box',
                                    backgroundColor: 'white',
                                }}
                            >
                                <Stack direction="column" spacing={7}>

                                    {/* Title */}
                                    <item>
                                        <TitleWidget title="Directors" />
                                    </item>
                                    <item>
                                        <Stack direction="column" spacing={7}>
                                            {
                                                getResult != null && Array.isArray(getResult)
                                                    ?
                                                    getResult.map((step, index) => {
                                                        while (step.post_title.match(/Organization - Director/))
                                                            return <item>
                                                                <Stack direction={{ xs: 'column', md: 'row' }} alignItems={{ xs: 'center', md: 'flex-start' }} spacing={6}>
                                                                    {/* Profile Image */}
                                                                    <item>
                                                                        <Box
                                                                            sx={{
                                                                                width: '200px',
                                                                                height: '200px',
                                                                                borderRadius: '50%',
                                                                                backgroundImage: 'url(' + step.post_content.slice(step.post_content.indexOf("https"), step.post_content.indexOf("\" alt")) + ')',
                                                                                backgroundSize: 'cover',
                                                                            }}
                                                                        />
                                                                    </item>


                                                                    {/* Profile Text */}
                                                                    <item>
                                                                        <Stack direction="column" spacing={2}>
                                                                            <item>
                                                                                <Typography variant="h5" sx={{ color: '#232323', fontWeight: 'bold', fontFamily: 'Open Sans', }}>
                                                                                    {step.post_content.slice((29 + step.post_content.indexOf("<h3")), step.post_content.indexOf("</h3>"))}
                                                                                </Typography>
                                                                            </item>
                                                                            <item>
                                                                                <Typography variant="h5" sx={{ color: '#084C84', fontWeight: 'bold', fontFamily: 'Open Sans', }}>
                                                                                    {step.post_content.slice(49, step.post_content.indexOf("</h2>"))}
                                                                                </Typography>
                                                                            </item>
                                                                            <item>
                                                                                <Typography variant="h6" sx={{ color: '#232323', fontFamily: 'Poppins', fontWeight: 'normal' }}>
                                                                                    {step.post_content.slice((3 + step.post_content.indexOf("<p>")), step.post_content.indexOf("</p>"))}
                                                                                </Typography>
                                                                            </item>
                                                                        </Stack>
                                                                    </item>
                                                                </Stack>
                                                            </item>
                                                        return null;
                                                    })
                                                    : null
                                            }
                                        </Stack>
                                    </item>
                                </Stack>
                            </Box>
                        </item>
                        <item>
                            <Box
                                sx={{
                                    width: '100%',
                                    p: { xs: 4, md: 8 },
                                    boxSizing: 'border-box',
                                    backgroundColor: 'white',
                                }}
                            >
                                <Stack direction="column" spacing={7}>

                                    {/* Title */}
                                    <item>
                                        <TitleWidget title="Structure" />
                                    </item>
                                    {
                                        getResult != null && Array.isArray(getResult)
                                            ?
                                            getResult.map((step, index) => {
                                                while (step.post_title.match(/Organization - Structure/))
                                                    return <item>
                                                        <Box
                                                            width="100%"
                                                        >
                                                            <img src={step.post_content.slice(step.post_content.indexOf("https"), step.post_content.indexOf("\" alt"))} alt="" width="100%" />
                                                        </Box>
                                                    </item>
                                                return null;
                                            })
                                            : null
                                    }


                                </Stack>
                            </Box>
                        </item>
                    </Stack>
                </Box>
            </item>
        </Stack>
    </Box>
}

export default Organization;

