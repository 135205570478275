import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Layout from './component/layout';
import Home from './pages/home';
import About from './pages/about';
import Organization from './pages/organization';
import Certificate from './pages/certificate';
import Activity from './pages/activity';
import Gallery from './pages/gallery';
import Services from './pages/services';
import Career from './pages/career';
import Contact from './pages/contact';
import News from './pages/news';
import ArticlePage from './pages/articlepage';
import { ThemeProvider } from '@mui/material';
import theme from './component/theme';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "organization",
        element: <Organization />,
      },
      {
        path: "certificate",
        element: <Certificate />,
      },
      {
        path: "activity",
        element: <Activity />,
      },
      {
        path: "gallery",
        element: <Gallery />,
      },
      {
        path: "services",
        element: <Services />,
      },
      {
        path: "career",
        element: <Career />,
      },
      {
        path: "contact-us",
        element: <Contact />,
      },
      {
        path: "news",
        element: <News />,
      },
      {
        path: "news/:id",
        element: <ArticlePage />,
      }
    ]
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
