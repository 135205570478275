import React, { useEffect, useState } from 'react';
import apiClient from "../http-common";
import {
    Box,
    Typography,
    Stack,
    Grid,
    Autocomplete,
    TextField,
    Pagination
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import LoadingWidget from '../widget/loadingWidget';

const sampleData = [
    {
        title: "John Wick 1",
    },
    {
        title: "John Wick 2",
    },
    {
        title: "John Wick 3",
    },
    {
        title: "John Wick 4",
    },
    {
        title: "John Wick 5",
    },
];

function UsePagination(data, itemsPerPage) {
    const [currentPage, setCurrentPage] = useState(1);

    if (data === null) return null;

    const maxPage = Math.ceil(data.length / itemsPerPage);

    function currentData() {
        const begin = (currentPage - 1) * itemsPerPage;
        const end = begin + itemsPerPage;
        return data.slice(begin, end);
    }

    function next() {
        setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
    }

    function prev() {
        setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
    }

    function jump(page) {
        const pageNumber = Math.max(1, page);
        setCurrentPage(currentPage => Math.min(pageNumber, maxPage));
    }

    return { next, prev, jump, currentData, currentPage, maxPage };
}

const News = () => {
    const formatResponse = (res) => {
        return JSON.stringify(res, null, 2);
    };

    // Pagination needs
    let [page, setPage] = useState(1);
    const PER_PAGE = 5;

    const [count, setCount] = useState(0);
    const [dataSelected, setDataSelected] = useState(null);
    const data = UsePagination(dataSelected, PER_PAGE);

    const handleChange = (e, p) => {
        setPage(p);
        data.jump(p);
    };


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
        async function getAllData() {
            try {
                const res = await apiClient.get("get_artikel/post/?per_page=100");

                const result = {
                    status: res.status + "-" + res.statusText,
                    headers: res.headers,
                    data: res.data,
                };
                return result.data;
            } catch (err) {
                console.log(err);
                setDataSelected(formatResponse(err.response?.data || err));
            }
        }

        function dataSelection(data) {
            const thedata = [];
            for (let i = 0; i < data.length; i++) {
                if (data[i].post_title.match(/Artikel -/)) {
                    thedata.push(data[i]);
                }
            }
            console.log(thedata);
            setDataSelected(thedata);
            setCount(Math.ceil(thedata.length / PER_PAGE));
        }

        (async () => {
            const latestData = await getAllData();
            if (latestData !== null && Array.isArray(latestData)) {
                console.log(latestData !== null ? latestData.length : 'none')
                dataSelection(latestData);
            }
        })();
    }, []);

    const getDescription = (stringHTML) => {
        let parser = new DOMParser();
        const doc = parser.parseFromString(stringHTML, 'text/html');
        const collection = doc.getElementsByTagName('p');
        return collection[0].outerText;
    }

    return <Box m={0} p={{ xs: 2, md: 4 }} sx={{ boxSizing: 'border-box' }}>
        <Stack direction="column" alignItems="stretch" spacing={2}>
            <item>
                <Typography variant="h3" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', textAlign: 'center' }}>
                    NEWS
                </Typography>
            </item>
            <item>
                {
                    dataSelected != null && Array.isArray(dataSelected)
                        ? <Box
                            py={{ xs: 6, md: 10 }}
                            px={{ xs: 2, md: 8 }}
                            sx={{
                                backgroundColor: 'white',
                                borderRadius: '20px',
                            }}
                        >
                            <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={5}>
                                <Grid item>
                                    {/* Search bar */}
                                    <Box maxWidth="225px">
                                        <Autocomplete
                                            freeSolo
                                            options={sampleData.map((option) => option.title)}
                                            renderInput={(params) => <TextField {...params} label="Search..." />}
                                        />
                                    </Box>
                                </Grid>
                                {
                                    data.currentData().map((step, index) => {
                                        while (step.post_title.match(/Artikel -/))
                                            return <Grid item key={step.ID}>
                                                {/* Post */}
                                                <Grid container direction="column" spacing={0}>
                                                    <Grid item>
                                                        {/* Title */}
                                                        <NavLink to={step.ID} style={{ color: '#084C84', textDecoration: 'none' }}>
                                                            <Typography variant="h4" sx={{ fontFamily: 'Open Sans', fontWeight: 'bold', '&:hover': { color: 'black' } }}>
                                                                {step.post_title.substring(10)}
                                                            </Typography>
                                                        </NavLink>
                                                    </Grid>
                                                    <Grid item>
                                                        {/* Post Information */}
                                                        <Typography variant="subtitle1" sx={{ fontFamily: 'Poppins', color: '#4F4F4F' }}>
                                                            by Admin at {step.post_date.split(' ')[0]}
                                                            {/* Admin / 10 Feb 2023 */}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        {/* Post Information */}
                                                        <Typography variant="subtitle1" sx={{ fontFamily: 'Poppins' }}>
                                                            {/* <div dangerouslySetInnerHTML={{ __html: step.post_content.split('<!-- /wp:paragraph -->')[0] }} /> */}
                                                            {getDescription(step.post_content).substring(0, 400)}...
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        {/* Border Bottom */}
                                                        <Box width="100%" height="1px" backgroundColor="#BFBFBF" />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        return null;
                                    })
                                }
                                <Grid item>
                                    {/* Pagination */}
                                    <Pagination
                                        spacing={2}
                                        showFirstButton
                                        showLastButton
                                        count={count ?? 0}
                                        size="large"
                                        page={page}
                                        variant="outlined"
                                        shape="rounded"
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        : dataSelected === null ? <LoadingWidget /> : <Typography variant="h3">Failed to load.</Typography>
                }
            </item>
        </Stack>
    </Box >
}

export default News;
