import React, { useEffect, useState } from 'react';
import axios from "axios";
import {
    Box,
    Grid,
    Stack,
    Dialog,
    Grow
} from '@mui/material';
import HeaderPage from '../component/headerPage';

// Import Assets
import Background from '../assets/img/certificateImg.png';
import LoadingWidget from '../widget/loadingWidget';

const GrowTransition = React.forwardRef(function GrowTransition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

const Certificate = () => {
    const [getResult, setGetResult] = useState(null);
    const formatResponse = (res) => {
        return JSON.stringify(res, null, 2);
    };

    useEffect(() => {
        async function getAllData() {
            try {
                const res = await axios.get("https://admin.multipirantijaya.com/wp/wp-json/wp/v2/media/?per_page=100");

                const result = {
                    status: res.status + "-" + res.statusText,
                    headers: res.headers,
                    data: res.data,
                };
                setGetResult(result.data);
            } catch (err) {
                setGetResult(formatResponse(err.response?.data || err));
            }
        }
        getAllData();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    }, []);

    const [openImage, setOpenImage] = React.useState(false);
    const handleOpenImage = (img) => {
        setOpenImage(true);
        handleCurrentImage(img);
    }
    const handleCloseImage = () => {
        setOpenImage(false);
    }

    const [currentImage, setCurrentImage] = React.useState(null);
    const handleCurrentImage = (img) => {
        setCurrentImage(img);
    }

    return <Box m={0} p={0}>
        <Stack direction="column" spacing={4}>
            <item>
                <HeaderPage title="Certificate" img={Background} />
            </item>
            <item>
                <Box
                    m={4}
                >
                    <Grid container justifyContent="space-between" alignItems="flex-start" columnSpacing={3} rowSpacing={1}>
                        {
                            getResult != null && Array.isArray(getResult)
                                ?
                                getResult.map((step, index) => {
                                    while (step.title.rendered.match(/Certificate/))
                                        return <Grid item>
                                            <Box
                                                onClick={() => { handleOpenImage(step.source_url); }}
                                                p={4}
                                                backgroundColor="white"
                                                height="345px"
                                                sx={{ borderRadius: '20px' }}
                                            >
                                                <img src={step.source_url} alt="" height="100%" />
                                            </Box>
                                        </Grid>
                                    return null;
                                })
                                : <LoadingWidget />
                        }
                    </Grid>
                </Box>
            </item>
        </Stack>

        {/* Open Image */}
        <Dialog
            fullScreen
            open={openImage}
            onClose={handleCloseImage}
            TransitionComponent={GrowTransition}
            PaperProps={{ style: { backgroundColor: 'transparent' }, onClick: handleCloseImage, }}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="auto"
                height="auto"
                mx="auto"
                my="auto"
            >
                <img src={currentImage} alt="" width="100%" />
            </Box>
        </Dialog>
    </Box>
}

export default Certificate;