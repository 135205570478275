import React from 'react';
import { Outlet, NavLink } from "react-router-dom";
import {
    Box,
    Grid,
    Link,
    Typography,
    Button,
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    MenuItem,
    MenuList,
    AppBar,
    Container,
    Toolbar,
    IconButton,
    Menu,
} from '@mui/material';
import Logo from '../assets/img/logompj.png';
import Maps from '../assets/img/maps.png';
import IndFlag from '../assets/img/indFlag.png';
import EngFlag from '../assets/img/engFlag.png';


import {
    Linkedin,
    Facebook,
    Twitter,
    Instagram,
    ArrowDownCircle,
    Menu as MenuIcon
} from 'react-feather';

const pages = [
    {
        'text': {
            en: 'Home',
            id: 'Beranda'
        },
        'link': '/'
    },
    {
        'text': {
            en: 'About',
            id: 'Tentang'
        },
        'link': '#'
    },
    {
        'text': {
            en: 'Services',
            id: 'Jasa'
        },
        'link': '/services'
    },
    {
        'text': {
            en: 'Career',
            id: 'Karir'
        },
        'link': '/career'
    },
    {
        'text': {
            en: 'News',
            id: 'Berita'
        },
        'link': '/news'
    },
    {
        'text': {
            en: 'Contact',
            id: 'Kontak'
        },
        'link': '/contact-us'
    },
];

const Layout = () => {

    const [aboutMenuOpen, setAboutMenuOpen] = React.useState(false);
    const [aboutMenuOpenXS, setAboutMenuOpenXS] = React.useState(false);
    const [langMenuOpen, setLangMenuOpen] = React.useState(false);
    const [language, setLanguage] = React.useState(0);
    const aboutRef = React.useRef(null);
    const aboutRefXS = React.useRef(null);
    const langRef = React.useRef(null);

    const handleAboutToggle = () => {
        setAboutMenuOpen((prevOpen) => !prevOpen);
    }

    const handleAboutToggleXS = () => {
        setAboutMenuOpenXS((prevOpen) => !prevOpen);
    }

    const handleLangToggle = () => {
        setLangMenuOpen((prevOpen) => !prevOpen);
    }

    const handleClose = (event) => {
        if (aboutRef.current && aboutRef.current.contains(event.target)) {
            return;
        }
        if (aboutRefXS.current && aboutRefXS.current.contains(event.target)) {
            return;
        }

        if (langRef.current && langRef.current.contains(event.target)) {
            return;
        }

        setLangMenuOpen(false);
        setAboutMenuOpen(false);
        setAboutMenuOpenXS(false);
    }

    const [navbarBackground, setNavbarBackground] = React.useState('rgba(255,255,255,0.0)');
    const handleScroll = () => {
        const position = window.pageYOffset;
        if (position > 0) {
            setNavbarBackground('rgba(255,255,255,0.8)');
        } else {
            setNavbarBackground('rgba(255,255,255,0.0)');
        }
    };

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // RESPONSIVE MENU
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <Box
            m={0}
            p={0}
            mt="102px"
        >
            <AppBar
                position="static"
                elevation={0}
                sx={{
                    position: 'fixed',
                    top: '0',
                    width: '100%',
                    boxSizing: 'border-box',
                    backgroundColor: navbarBackground,
                    transition: '0.5s',
                    zIndex: '100',
                    py: 1,
                }}
            >
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        {/* in Medium size */}
                        <Box height="50px" sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
                            <img src={Logo} alt="" height="100%" />
                        </Box>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'Poppins',
                                fontWeight: 700,
                                color: '#551A8B',
                                textDecoration: 'none',
                            }}
                        >
                            PT. Multi Piranti Jaya
                        </Typography>

                        {/* in Small size */}
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon color="black" />
                            </IconButton>

                            <Popper
                                open={aboutMenuOpenXS}
                                anchorEl={aboutRefXS.current}
                                role={undefined}
                                placement="right-start"
                                transition
                                disablePortal
                                sx={{ zIndex: 1 }}
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin: placement === 'right-start' ? 'left top' : 'left bottom'
                                        }}
                                    >
                                        <Paper>
                                            <ClickAwayListener
                                                onClickAway={handleClose}
                                            >
                                                <MenuList
                                                    autoFocusItem={aboutMenuOpenXS}
                                                    id="composition-menu"
                                                    aria-labelledby="composition-button"
                                                >
                                                    <NavLink style={{ textDecoration: 'none', color: 'black' }} to="/about">
                                                        <MenuItem onClick={handleClose}>
                                                            {language === 0 ? "About Us" : "Tentang Kita"}
                                                        </MenuItem>
                                                    </NavLink>
                                                    <NavLink style={{ textDecoration: 'none', color: 'black' }} to="/organization">
                                                        <MenuItem onClick={handleClose}>
                                                            {language === 0 ? "Organization" : "Organisasi"}
                                                        </MenuItem>
                                                    </NavLink>
                                                    <NavLink style={{ textDecoration: 'none', color: 'black' }} to="/certificate">
                                                        <MenuItem onClick={handleClose}>
                                                            {language === 0 ? "Certification" : "Sertifikat"}
                                                        </MenuItem>
                                                    </NavLink>
                                                    <NavLink style={{ textDecoration: 'none', color: 'black' }} to="/activity">
                                                        <MenuItem onClick={handleClose}>
                                                            {language === 0 ? "Activity" : "Aktifitas"}
                                                        </MenuItem>
                                                    </NavLink>
                                                    <NavLink style={{ textDecoration: 'none', color: 'black' }} to="/gallery">
                                                        <MenuItem onClick={handleClose}>
                                                            {language === 0 ? "Gallery" : "Galeri"}
                                                        </MenuItem>
                                                    </NavLink>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>

                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                    zIndex: 0,
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page.link} onClick={page.text.en === "About" ? null : handleCloseNavMenu}>
                                        <NavLink style={{ textDecoration: 'none' }} to={page.link}>
                                            <Button
                                                ref={page.text.en === "About" ? aboutRefXS : null}
                                                aria-haspopup={page.text.en === "About"}
                                                onClick={page.text.en === "About" ? handleAboutToggleXS : null}
                                                sx={{
                                                    color: '#551A8B',
                                                    fontWeight: 'bold',
                                                    fontFamily: 'Poppins',
                                                    textTransform: 'none'
                                                }}
                                            >
                                                <Typography
                                                    variant='h6'
                                                    sx={{
                                                        fontFamily: 'Poppins',
                                                        fontWeight: 700,
                                                        color: '#551A8B',
                                                        textDecoration: 'none',
                                                    }}>
                                                    {language === 0 ? page.text.en : page.text.id}
                                                </Typography>
                                            </Button>
                                        </NavLink>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href=""
                            sx={{
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'Poppins',
                                fontWeight: 700,
                                color: '#551A8B',
                                textDecoration: 'none',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            PT. Multi Piranti Jaya
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end', }}>
                            <Button
                                ref={langRef}
                                id="compisition-button"
                                aria-controls={langMenuOpen ? 'composition-menu' : undefined}
                                aria-expanded={langMenuOpen ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleLangToggle}
                                sx={{
                                    backgroundColor: '#D6E0E9',
                                    color: '#551A8B',
                                    fontSize: '24px',
                                    fontWeight: 'bold',
                                    fontFamily: 'Poppins',
                                    textTransform: 'none',
                                    ml: 2,
                                }}
                            >
                                <ArrowDownCircle size={16} sx={{ color: 'black' }} />
                                <Box sx={{ width: '5px', height: '5px' }} />
                                <img src={language === 1 ? IndFlag : EngFlag} alt="" />
                            </Button>
                            <Popper
                                open={langMenuOpen}
                                anchorEl={langRef.current}
                                role={undefined}
                                placement="bottom-start"
                                transition
                                disablePortal
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom'
                                        }}
                                    >
                                        <Paper>
                                            <ClickAwayListener
                                                onClickAway={handleClose}
                                            >
                                                <MenuList
                                                    autoFocusItem={aboutMenuOpen}
                                                    id="composition-menu"
                                                    aria-labelledby="composition-button"
                                                >
                                                    <MenuItem onClick={() => { setLanguage(0); handleClose(); }}>English</MenuItem>
                                                    <MenuItem onClick={() => { setLanguage(1); handleClose(); }}>Indonesia</MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </Box>
                        {/* Show menu when at big viewport */}
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end', }}>
                            {pages.map((page) => (
                                <Box key={page.link}>
                                    <NavLink style={{ textDecoration: 'none' }} to={page.link}>
                                        <Button
                                            ref={page.text.en === "About" ? aboutRef : null}
                                            aria-haspopup={page.text.en === "About"}
                                            onClick={page.text.en === "About" ? handleAboutToggle : null}
                                            sx={{
                                                color: '#551A8B',
                                                fontWeight: 'bold',
                                                fontFamily: 'Poppins',
                                                textTransform: 'none'
                                            }}
                                        >
                                            <Typography
                                                variant='h6'
                                                sx={{
                                                    fontFamily: 'Poppins',
                                                    fontWeight: 700,
                                                    color: '#551A8B',
                                                    textDecoration: 'none',
                                                }}>
                                                {language === 0 ? page.text.en : page.text.id}
                                            </Typography>
                                        </Button>
                                    </NavLink>

                                    {
                                        page.text.en === "About"
                                            ? <Popper
                                                open={aboutMenuOpen}
                                                anchorEl={aboutRef.current}
                                                role={undefined}
                                                placement="bottom-start"
                                                transition
                                                disablePortal
                                            >
                                                {({ TransitionProps, placement }) => (
                                                    <Grow
                                                        {...TransitionProps}
                                                        style={{
                                                            transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom'
                                                        }}
                                                    >
                                                        <Paper>
                                                            <ClickAwayListener
                                                                onClickAway={handleClose}
                                                            >
                                                                <MenuList
                                                                    autoFocusItem={aboutMenuOpen}
                                                                    id="composition-menu"
                                                                    aria-labelledby="composition-button"
                                                                >
                                                                    <NavLink style={{ textDecoration: 'none', color: 'black' }} to="/about">
                                                                        <MenuItem onClick={handleClose}>
                                                                            {language === 0 ? "About Us" : "Tentang Kita"}
                                                                        </MenuItem>
                                                                    </NavLink>
                                                                    <NavLink style={{ textDecoration: 'none', color: 'black' }} to="/organization">
                                                                        <MenuItem onClick={handleClose}>
                                                                            {language === 0 ? "Organization" : "Organisasi"}
                                                                        </MenuItem>
                                                                    </NavLink>
                                                                    <NavLink style={{ textDecoration: 'none', color: 'black' }} to="/certificate">
                                                                        <MenuItem onClick={handleClose}>
                                                                            {language === 0 ? "Certification" : "Sertifikat"}
                                                                        </MenuItem>
                                                                    </NavLink>
                                                                    <NavLink style={{ textDecoration: 'none', color: 'black' }} to="/activity">
                                                                        <MenuItem onClick={handleClose}>
                                                                            {language === 0 ? "Activity" : "Aktifitas"}
                                                                        </MenuItem>
                                                                    </NavLink>
                                                                    <NavLink style={{ textDecoration: 'none', color: 'black' }} to="/gallery">
                                                                        <MenuItem onClick={handleClose}>
                                                                            {language === 0 ? "Gallery" : "Galeri"}
                                                                        </MenuItem>
                                                                    </NavLink>
                                                                </MenuList>
                                                            </ClickAwayListener>
                                                        </Paper>
                                                    </Grow>
                                                )}
                                            </Popper>
                                            : null}
                                </Box>
                            ))}
                            {/* Change Language */}
                            <Button
                                ref={langRef}
                                id="compisition-button"
                                aria-controls={langMenuOpen ? 'composition-menu' : undefined}
                                aria-expanded={langMenuOpen ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleLangToggle}
                                sx={{
                                    backgroundColor: '#D6E0E9',
                                    color: '#551A8B',
                                    fontSize: '24px',
                                    fontWeight: 'bold',
                                    fontFamily: 'Poppins',
                                    textTransform: 'none',
                                    ml: 2,
                                }}
                            >
                                <ArrowDownCircle size={16} sx={{ color: 'black' }} />
                                <Box sx={{ width: '5px', height: '5px' }} />
                                <img src={language === 1 ? IndFlag : EngFlag} alt="" />
                            </Button>
                            <Popper
                                open={langMenuOpen}
                                anchorEl={langRef.current}
                                role={undefined}
                                placement="bottom-start"
                                transition
                                disablePortal
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom'
                                        }}
                                    >
                                        <Paper>
                                            <ClickAwayListener
                                                onClickAway={handleClose}
                                            >
                                                <MenuList
                                                    autoFocusItem={aboutMenuOpen}
                                                    id="composition-menu"
                                                    aria-labelledby="composition-button"
                                                >
                                                    <MenuItem onClick={() => { setLanguage(0); handleClose(); }}>English</MenuItem>
                                                    <MenuItem onClick={() => { setLanguage(1); handleClose(); }}>Indonesia</MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Outlet />
            <Box
                sx={{
                    width: '100%',
                    p: 6,
                    backgroundColor: '#084C84',
                    backgroundImage: 'linear-gradient(to right, #084C84, #216BA7, #003967)',
                    boxSizing: 'border-box',
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent={{ xs: 'center', sm: 'space-between' }}
                    alignItems={{ xs: 'center', sm: 'flex-start' }}
                    sx={{ color: 'white' }}
                    spacing={4}
                >
                    <Grid item xs={12} sm={6} md={4}>
                        <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={4}>
                            <Grid item>
                                <Typography variant='h4' sx={{ fontWeight: 'bold', fontFamily: 'Poppins' }}>
                                    Web Index
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <NavLink style={{ textDecoration: 'none' }} to="/about">
                                            <Typography variant="subtitle1" sx={{ color: '#E0E0E0' }}>About Us</Typography>
                                        </NavLink>
                                    </Grid>
                                    <Grid item>
                                        <NavLink style={{ textDecoration: 'none' }} to="/contact-us">
                                            <Typography variant="subtitle1" sx={{ color: '#E0E0E0' }}>Contact</Typography>
                                        </NavLink>
                                    </Grid>
                                    <Grid item>
                                        <NavLink style={{ textDecoration: 'none' }} to="/services">
                                            <Typography variant="subtitle1" sx={{ color: '#E0E0E0' }}>Services</Typography>
                                        </NavLink>
                                    </Grid>
                                    <Grid item>
                                        <NavLink style={{ textDecoration: 'none' }} to="/career">
                                            <Typography variant="subtitle1" sx={{ color: '#E0E0E0' }}>Career</Typography>
                                        </NavLink>
                                    </Grid>
                                    <Grid item>
                                        <NavLink style={{ textDecoration: 'none' }} to="/news">
                                            <Typography variant="subtitle1" sx={{ color: '#E0E0E0' }}>News</Typography>
                                        </NavLink>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                            <Grid item>
                                <Typography variant='h4' sx={{ fontWeight: 'bold', fontFamily: 'Poppins' }}>
                                    Contact Us
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <Typography variant="subtitle1" sx={{ color: '#E0E0E0' }}>
                                            021 123 456 7890 <br />
                                            Jl. Niaga I No. 23, Kota Deli 12345<br />
                                            halo@stiussupercanggih.co.id
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Link href="https://goo.gl/maps/SyoUm8LAnSwSKSveA" target="_blank">
                                            <img src={Maps} alt="" />
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                            <Grid item>
                                <Typography variant='h4' sx={{ fontWeight: 'bold', fontFamily: 'Poppins' }}>
                                    Social Media
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" spacing={3}>
                                    <Grid item>
                                        <Link href="https://www.linkedin.com/company/pt-multi-piranti-jaya/" target="_blank" underline="none" sx={{ color: 'white' }}>
                                            <Grid container direction="row" spacing={2} alignItems="center" >
                                                <Grid item>
                                                    <Linkedin size={24} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle1">
                                                        LinkedIn
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link href="#" target="_blank" underline="none" sx={{ color: 'white' }}>
                                            <Grid container direction="row" spacing={2} alignItems="center" >
                                                <Grid item>
                                                    <Facebook size={24} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle1">
                                                        Facebook
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link href="#" target="_blank" underline="none" sx={{ color: 'white' }}>
                                            <Grid container direction="row" spacing={2} alignItems="center" >
                                                <Grid item>
                                                    <Instagram size={24} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle1">
                                                        Instagram
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link href="#" target="_blank" underline="none" sx={{ color: 'white' }}>
                                            <Grid container direction="row" spacing={2} alignItems="center" >
                                                <Grid item>
                                                    <Twitter size={24} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle1">
                                                        Twitter
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Box
                    display="flex"
                    alignItems={{ xs: 'center', md: 'flex-end' }}
                    justifyContent={{ xs: 'center', md: 'flex-end' }}
                    sx={{ fontFamily: 'Poppins', fontSize: { xs: '16px', md: '24px' }, color: '#CECECE' }}
                    mt={5}
                >
                    © PT. Multi Piranti Jaya 2023
                </Box>
            </Box>
        </Box >
    )
}

export default Layout;