import React, { useEffect, useState } from 'react';
import apiClient from "../http-common";
import {
    Box,
    Typography,
    Stack
} from '@mui/material';

import Background from '../assets/img/aboutImg.png';
import HeaderPage from '../component/headerPage';
import LoadingWidget from '../widget/loadingWidget';

const About = () => {
    const [getResult, setGetResult] = useState(null);
    const formatResponse = (res) => {
        return JSON.stringify(res, null, 2);
    };

    useEffect(() => {
        async function getAllData() {
            try {
                const res = await apiClient.get("get_artikel/post/?per_page=100");

                const result = {
                    status: res.status + "-" + res.statusText,
                    headers: res.headers,
                    data: res.data,
                };
                setGetResult(result.data);
            } catch (err) {
                setGetResult(formatResponse(err.response?.data || err));
            }
        }
        getAllData();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    }, []);

    return <Box
        id="About"
        m={0}
        p={0}
    >
        <Stack direction="column" spacing={4}>

            <item><HeaderPage title="About Our Company" img={Background} /></item>
            <item><Box
                // width="100%"
                mx={{ xs: 2, md: 4 }}
                mb={3}
                p={{ xs: 4, md: 8 }}
                sx={{
                    backgroundColor: 'white',
                    boxSizing: 'border-box',
                    borderRadius: '20px'
                }}
            >
                <Stack direction="column" spacing={5}>
                    {
                        getResult != null
                            ?
                            getResult.map((step, index) => {
                                while (step.post_title.match(/About Us -/))
                                    return <Stack direction="column" spacing={2}>
                                        <item>
                                            <Typography variant="h5" sx={{ fontFamily: 'Open Sans', fontWeight: 'bold', color: '#232323' }}>
                                                {step.post_title.substring(11)}
                                            </Typography>
                                        </item>
                                        <item>
                                            <Typography variant="subtitle1" sx={{ fontFamily: 'Poppins', color: '#232323' }}>
                                                <div dangerouslySetInnerHTML={{ __html: step.post_content }} />
                                            </Typography>
                                        </item>
                                    </Stack>

                                return null;
                            })
                            : <LoadingWidget />
                    }
                </Stack>
            </Box>
            </item>

        </Stack>
    </Box>
}

export default About;