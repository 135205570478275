import React, { useEffect, useState } from 'react';
import apiClient from "../http-common";
import {
    Box,
    Stack,
    Typography
} from '@mui/material';
import HeaderPage from '../component/headerPage';

// Import Assets
import Background from '../assets/img/activityImg.jpeg';
import LoadingWidget from '../widget/loadingWidget';

const Activity = () => {
    const [getResult, setGetResult] = useState(null);
    const formatResponse = (res) => {
        return JSON.stringify(res, null, 2);
    };

    useEffect(() => {
        async function getAllData() {
            try {
                const res = await apiClient.get("get_artikel/post/?per_page=100");

                const result = {
                    status: res.status + "-" + res.statusText,
                    headers: res.headers,
                    data: res.data,
                };
                setGetResult(result.data);
            } catch (err) {
                setGetResult(formatResponse(err.response?.data || err));
            }
        }
        getAllData();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    }, []);


    return <Box m={0} p={0}>
        <Stack direction="column" spacing={7}>
            <item>
                <HeaderPage title="Activity" img={Background} />
            </item>
            <item>
                <Box m={{ xs: 4, md: 7 }}>
                    <Stack direction="column" spacing={8}>
                        {
                            getResult != null
                                ?
                                getResult.map((step, index) => {
                                    while (step.post_title.match(/Activity -/))
                                        return <item>
                                            <Stack direction="column" spacing={8}>

                                                {/* Activiy */}
                                                <item>
                                                    <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" alignItems="center" spacing={{ xs: 5, md: 10 }}>
                                                        {/* Image */}
                                                        <item>
                                                            <Box
                                                                sx={{
                                                                    width: { xs: '350px', md: '500px' },
                                                                    height: { xs: '230px', md: '300px' },
                                                                    backgroundImage: 'url(' + step.post_content.slice(step.post_content.indexOf("https"), step.post_content.indexOf("\" alt")) + ')',
                                                                    backgroundSize: 'cover',
                                                                    backgroundPosition: '50% 50%',
                                                                    boxShadow: '5px 5px 5px rgba(0,0,0,0.2)'
                                                                }}
                                                            />
                                                        </item>

                                                        {/* Content */}
                                                        <item>
                                                            <Stack direction="column" spacing={4}>
                                                                <item>
                                                                    <Typography variant="h4" sx={{ fontFamily: 'Open Sans', fontWeight: 'bold', color: '#084C84' }}>
                                                                        {step.post_title.substring(11)}
                                                                    </Typography>
                                                                </item>

                                                                <item>
                                                                    <Typography variant="subtitle1" sx={{ fontFamily: 'Poppins', color: '#232323' }}>
                                                                        {step.post_content.slice((3 + step.post_content.indexOf("<p>")), step.post_content.indexOf("</p>"))}
                                                                    </Typography>
                                                                </item>
                                                            </Stack>
                                                        </item>

                                                    </Stack>
                                                </item>

                                                {/* Horizontal Line */}
                                                <item>
                                                    <Box m={{ xs: 4, md: 7 }} height="1px" backgroundColor="#BBBBBB" />
                                                </item>
                                            </Stack>
                                        </item>
                                    return null;
                                })
                                : <LoadingWidget />
                        }
                    </Stack>
                </Box>
            </item>
        </Stack>
    </Box>
}

export default Activity;