import React, { useEffect, useState } from 'react';
import axios from "axios";
import {
    Box,
    Typography,
    Stack,
    Grid,
    Dialog,
    Grow,
} from '@mui/material';
import LoadingWidget from '../widget/loadingWidget';

// Import Assets

const GrowTransition = React.forwardRef(function GrowTransition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

// ********** ADD PAGINATION **********
const Gallery = () => {
    const [getResult, setGetResult] = useState(null);
    const formatResponse = (res) => {
        return JSON.stringify(res, null, 2);
    };

    useEffect(() => {
        async function getAllData() {
            try {
                const res = await axios.get("https://admin.multipirantijaya.com/wp/wp-json/wp/v2/media/?per_page=100");

                const result = {
                    status: res.status + "-" + res.statusText,
                    headers: res.headers,
                    data: res.data,
                };
                setGetResult(result.data);
            } catch (err) {
                setGetResult(formatResponse(err.response?.data || err));
            }
        }
        getAllData();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    }, []);

    const [openImage, setOpenImage] = React.useState(false);
    const handleOpenImage = (img) => {
        setOpenImage(true);
        handleCurrentImage(img);
    }
    const handleCloseImage = () => {
        setOpenImage(false);
    }

    const [currentImage, setCurrentImage] = React.useState(null);
    const handleCurrentImage = (img) => {
        setCurrentImage(img);
    }

    return <Box m={0} mb={4} p={0}>
        <Stack direction="column" spacing={4}>
            <item>
                <Box
                    width="100%"
                    py={{ xs: 4, md: 7 }}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="h3" sx={{ color: '#084C84', fontFamily: 'Poppins', fontWeight: 'bold', textTransform: 'uppercase' }}>
                        Gallery
                    </Typography>
                </Box>
            </item>
            <item>
                <Grid container justifyContent="center" rowSpacing={{ xs: 3, md: 7 }} columnSpacing={{ xs: 3, md: 7 }}>
                    {
                        getResult != null && Array.isArray(getResult)
                            ?
                            getResult.map((step, index) => {
                                // step.media_details.sizes.full.source_url = step.media_details.sizes.full.source_url.replaceAll('\\', '')
                                while (step.title.rendered.match(/Gallery/))
                                    return <Grid item key={step.ID}>
                                        <Box
                                            onClick={() => { handleOpenImage(step.source_url); }}
                                            sx={{
                                                width: { xs: '100px', md: '250px' },
                                                height: { xs: '100px', md: '250px' },
                                                backgroundImage: 'url(' + step.source_url + ')',
                                                backgroundSize: 'cover',
                                                backgroundPosition: '50% 50%',
                                                borderRadius: '20px',
                                                cursor: 'pointer',
                                            }}
                                        />
                                    </Grid>
                                return null;
                            })
                            : <LoadingWidget />
                    }
                </Grid>
            </item>
        </Stack>

        {/* Open Image */}
        <Dialog
            fullScreen
            open={openImage}
            onClose={handleCloseImage}
            TransitionComponent={GrowTransition}
            PaperProps={{ style: { backgroundColor: 'transparent' }, onClick: handleCloseImage, }}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="auto"
                height="auto"
                mx="auto"
                my="auto"
            >
                <img src={currentImage} alt="" width="100%" />
            </Box>
        </Dialog>
    </Box>
}

export default Gallery;