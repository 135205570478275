import React, { useEffect, useState } from 'react';
import apiClient from "../http-common";
import {
    Box,
    Stack,
    Typography
} from '@mui/material';
import HeaderPage from '../component/headerPage';

// Import Assets
import Background from '../assets/img/activityImg.jpeg';
import LoadingWidget from '../widget/loadingWidget';

const Services = () => {
    const [getResult, setGetResult] = useState(null);
    const formatResponse = (res) => {
        return JSON.stringify(res, null, 2);
    };

    useEffect(() => {
        async function getAllData() {
            try {
                const res = await apiClient.get("get_artikel/post/?per_page=100");

                const result = {
                    status: res.status + "-" + res.statusText,
                    headers: res.headers,
                    data: res.data,
                };
                setGetResult(result.data);
            } catch (err) {
                setGetResult(formatResponse(err.response?.data || err));
            }
        }
        getAllData();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    }, []);


    return <Box m={0} p={0}>
        <Stack direction="column" spacing={4}>
            <item>
                <HeaderPage title="Services" img={Background} />
            </item>
            <item>
                <Stack direction="column" spacing={4}>
                    <item>
                        {/* Product & Services */}
                        <Box
                            mx={{ xs: 2, md: 4 }}
                            p={{ xs: 4, md: 7 }}
                            backgroundColor="white"
                            sx={{ boxSizing: 'border-box', borderRadius: '20px' }}
                        >
                            {
                                getResult != null
                                    ?
                                    getResult.map((step, index) => {
                                        while (step.post_title.match(/Services -/))
                                            return <Stack direction="column" spacing={2}>
                                                <item>
                                                    <Typography variant="h5" sx={{ fontFamily: 'Open Sans', fontWeight: 'bold', color: '#232323' }}>
                                                        {step.post_title.substring(10)}
                                                    </Typography>
                                                </item>
                                                <item>
                                                    <Typography variant="subtitle1" sx={{ fontFamily: 'Open Sans', color: '#232323' }}>
                                                        <div dangerouslySetInnerHTML={{ __html: step.post_content }} />
                                                    </Typography>
                                                </item>
                                            </Stack>
                                        return null;
                                    })
                                    : <LoadingWidget />
                            }

                        </Box>
                    </item>
                    <item>
                        {/* Our Services */}
                        <Box
                            mx={{ xs: 2, md: 4 }}
                            mb={3}
                            p={{ xs: 4, md: 7 }}
                            backgroundColor="white"
                            sx={{ boxSizing: 'border-box', borderRadius: '20px' }}
                        >
                            <Stack direction="column" spacing={5}>
                                {
                                    getResult != null
                                        ?
                                        getResult.map((step, index) => {
                                            while (step.post_title.match(/Services 2 -/))
                                                return <item>
                                                    <Typography variant="h5" sx={{ fontFamily: 'Open Sans', fontWeight: 'bold', color: '#232323' }}>
                                                        {step.post_title.substring(12)}
                                                    </Typography>
                                                </item>
                                            return null;
                                        })
                                        : null
                                }

                                <item>
                                    <Stack direction="column" spacing={5}>
                                        {
                                            getResult != null
                                                ?
                                                getResult.map((step, index) => {
                                                    while (step.post_title.match(/Services 2 -/))
                                                        return <item><Stack direction="column" spacing={2}>
                                                            <item>
                                                                <Typography variant="subtitle1" sx={{ fontFamily: 'Poppins', color: '#232323' }}>
                                                                    <div dangerouslySetInnerHTML={{ __html: step.post_content }} />
                                                                </Typography>
                                                            </item>
                                                        </Stack>
                                                        </item>
                                                    return null;
                                                })
                                                : null
                                        }
                                    </Stack>
                                </item>
                            </Stack>
                        </Box>
                    </item>
                </Stack>
            </item>
        </Stack >
    </Box >
}

export default Services;
