import React from 'react';
import { Box } from '@mui/material';

const LoadingWidget = () => {
    return <center>
        <Box
            mx={0}
            my={8}
            sx={{
                border: '16px solid #f3f3f3',
                borderRadius: '50%',
                borderTop: '16px solid #3498db',
                width: '120px',
                height: '120px',
                WebkitAnimation: 'spin 2s linear infinite', /* Safari */
                animation: 'spin 2s linear infinite',
            }}
        />
    </center>
}

export default LoadingWidget;